var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "side" },
    [
      _c(
        "router-link",
        {
          staticClass: "side-content button",
          class: { active: _vm.isActivePage(["nolaNovel"]) },
          attrs: { to: "/nolanovel" },
        },
        [_vm._v(" 投稿作品 ")]
      ),
      _vm.isConnectedNolaNovel
        ? _c(
            "router-link",
            {
              staticClass: "side-content button",
              class: { active: _vm.isActivePage(["nolaNovelNotification"]) },
              attrs: { to: "/nolanovel/notification" },
            },
            [
              _vm._v(" 受信箱 "),
              _vm.unreadNotificationsCount > 0
                ? _c("div", { staticClass: "batch" }, [
                    _vm._v(_vm._s(_vm.unreadNotificationsCountText)),
                  ])
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }

import { User } from "@/lib/models";
import Vue from "vue";

export default Vue.extend<Data, Methods, Computed, Props>({
  created() {
      // 未読通知数を取得
      this.$store.dispatch("notificationModule/getNotificationSummary", {
        notificationGroup: "agent",
      })
  },
  computed: {
    isActivePath() {
      return (name) => {
        const { path } = this.$route;
        return path === name || path === `${name}/`;
      };
    },
    user() {
      return this.$store.getters["userModule/user"];
    },
    unreadItemCount() {
      return this.$store.getters["notificationModule/notificationSummary"]?.unreadItemCount;
    }
  },
});

interface Props {}

interface Data {}

interface Computed {
  isActivePath(name: string): boolean;
  user: User;
  unreadItemCount: number
}

interface Methods {}
